import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import "./EmailForm.css"

const EmailForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_hsa67g9';
    const templateId = 'template_5yj43pk';
    const publicKey = '1EiRP8ap_dBQ2LaXz';

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Sasikumar',
      message: message,
    };

    if (!name || !email || !message) {
      console.log("Name, email, or message is empty. Email not sent.");
      return;
    }

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setIsEmailSent(true);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (
    <form onSubmit={handleSubmit} className='contactForm'>
      <h1 className="t-text">Contact Us</h1>
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="contact.name"
            required
          />
      <input
        type="email"
        placeholder="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="contact.email"
        required
      />
      <textarea
        cols="30"
        rows="10"
        placeholder="Please enter your request"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="contact.message"
        required
      >
      </textarea>
      <button type="submit" className='modernButton' >Send Email</button>
      {isEmailSent ? (
          <p className='success'>Email has been sent successfully</p>
        ) : (
          <p>Please provide your request, we will get back to you soon.</p> 
        )}
    </form>
  )
}

export default EmailForm